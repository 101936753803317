/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionProducts() {
  return (
    <>
      <div className="section text-center">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="title">Our Products and Services</h2>
              <h5 className="description">
                The <strong>Integrated Financials &amp; Operations Management System (IFOMS)</strong> is the
                flagship product of Ambersoft. A business management solution for small and
                medium-sized businesses.
              </h5>
            </Col>
          </Row>
          <br />
          <hr />
          <br />
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <ul className="ul-style-type-none" style={{ fontSize: '18px' }}>
                <li>Custom Software Design and Development</li>
                <li>Website Development</li>
                <li>IT Consultancy</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionProducts;
