/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionAbout() {
  return (
    <>
      <div className="section section-dark">
        <Container>
          <h2 className="title text-center">About Us</h2>
          <Row>
            <Col md="12">
              <h3 className="mb-2">Our Company</h3>
              <p className="text-justify mb-3">
                Ambersoft Incorporated started with just two programmers designing and developing
                customized applications for our diverse clientele. Due to the demand for our products and
                services, Ambersoft today is a full service operation that includes business analysts,
                experienced programmers, graphic artists and web designers.
              </p>
              <p className="text-justify mb-3">
                We work closely with our clients to automate their business procedures into user-friendly
                software applications that are customized to meet their requirements. Ambersoft prides
                itself on its ability to automate not only standard processes but also, the most
                customized and unique business operations utilized by our clients.
              </p>
              <p className="text-justify">
                The successful design and development of a client’s program is only one aspect of
                Ambersoft Consulting’s services. Another important element of our success is our
                commitment to our client relationships. At Ambersoft, our customers are fully supported
                from program design, development to implementation and beyond. Our technical support
                service is proven with reliability and efficiency because of our extensive experience. Our
                dedication in providing the best support to our customers always comes first.
              </p>
              <h3 className="mb-2">Mission</h3>
              <p className="text-justify mb-3">
                Assist our clients to maximize their revenue and minimize their expenditures through the
                automation of business processes packaged in user-friendly software applications.
              </p>
              <p className="text-justify">
                Providing our clients with the necessary support and training to ensure that their
                software application meets their requirements and that the client realizes the maximum
                potential from their customized program.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionAbout;
