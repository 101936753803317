/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ContactForm from "../../components/ContactForm.js";

function SectionContactUs() {
  return (
    <>
      <div className="section landing-section">
        <h2 className="text-center mb-2">Contact Us</h2>
        <br />
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="6">
              <Row tag="dl">
                <Col tag="dt" sm="1">
                  <div>
                    <i className="glyphicon fa fa-map-marker d-inline"></i>
                    <span className="d-sm-none dl-label font-weight-normal">Address</span>
                  </div>
                  
                </Col>
                <Col tag="dd" sm="11">
                  <p>25 11th Avenue, Socorro</p>
                  <p>Quezon City, 1109</p>
                  <p>Philippines</p>
                </Col>
                <Col tag="dt" sm="1">
                  <i className="glyphicon fa fa-phone d-inline"></i>
                  <span className="d-sm-none dl-label font-weight-normal">Phone</span>
                </Col>
                <Col tag="dd" sm="11">
                  <p>+63-2-8552-2707</p>
                </Col>
                <Col tag="dt" sm="1">
                  <i className="glyphicon fa fa-envelope d-inline"></i>
                  <span className="d-sm-none dl-label font-weight-normal">Email</span>
                </Col>
                <Col tag="dd" sm="11">
                  <p>info@ambersoft.com.ph</p>
                </Col>
              </Row>
            </Col>
            <Col className="ml-auto mr-auto" md="6">
              <p className="text-justify mb-3">
                We are looking forward to hearing from you. Please feel free to get in touch via the
                form below, we will get back to you as soon as possible.
              </p>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionContactUs;
