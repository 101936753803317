import React, { Component } from "react";

import {
  Button,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

class ContactForm extends Component {
  EMPTY = {
    name: "",
    email: "",
    message: ""
  };

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      message: "",
      errors: {}
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleValidation() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.name) {
      formIsValid = false;
      errors["name"] = "Cannot be empty.";
    }

    if (typeof this.state.name !== "undefined") {
      if (this.state.name.match(/[\r\n]/)) {
        formIsValid = false;
        errors["name"] = "Cannot contain newlines.";
      }
    }

    if (!this.state.email) {
      formIsValid = false;
      errors["email"] = "Cannot be empty.";
    }

    if (typeof this.state.email !== "undefined") {
      if (!this.state.email.match(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/i)) {
        formIsValid = false;
        errors["email"] = "Email is not valid.";
      }
    }

    if (!this.state.message) {
      formIsValid = false;
      errors["message"] = "Cannot be empty.";
    }

    if (typeof this.state.message === "string") {
      if (this.state.message.length >= 10000) {
        formIsValid = false;
        errors["message"] = "Must be less than 10,000 characters.";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(e) {
    let self = this;
    e.preventDefault();

    if (this.handleValidation()) {
      let data = new FormData();
      data.append("name", this.state.name);
      data.append("email", this.state.email);
      data.append("message", this.state.message);

      fetch('/contact', {
        method: 'POST',
        body: data
      }).then(function(res) {
        res.text().then(function(value) {
          alert(value);
          if (res.ok) {
            self.setState(self.EMPTY);
          }
        });
      }, function(e) {
        alert("Could not submit contact form.");
      });
    } else {
      alert("Contact form is not properly filled.");
    }
  }

  handleChange(e) {
    let target = e.target;
    let name = target.name;
    let value = target.value;

    this.setState({ [name]: value });
    this.handleValidation();
  }

  render() {
    return (
      <>
        <Form className="contact-form" onSubmit={this.handleSubmit}>
          <Row>
            <Col md="6">
              <label>Name</label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="nc-icon nc-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Name" type="text" name="name" onChange={this.handleChange} value={this.state.name} />
              </InputGroup>
            </Col>
            <Col md="6">
              <label>Email</label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="nc-icon nc-email-85" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Email" type="email" name="email" onChange={this.handleChange} value={this.state.email} />
              </InputGroup>
            </Col>
          </Row>
          <label>Message</label>
          <Input
            placeholder="Your message"
            type="textarea"
            name="message"
            rows="4"

            onChange={this.handleChange}
            value={this.state.message}
          />
          <Row>
            <Col className="ml-auto mr-auto" md="4">
              <Button className="btn-fill" color="danger" size="lg">
                Send
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    )
  }
}

export default ContactForm;
